// abis
import { abi as TokenABI } from 'abis/IERC20.json';
import { abi as StakingRewardsABI } from 'abis/StakingRewards.json';

// types
import { IERC20 } from 'types/IERC20';
import { StakingRewards } from 'types/StakingRewards';
import { AllowedChainConfig, ContractConfig } from 'types/config';

// assets
import logo from 'assets/Logo.png';

export const APP_CONFIG = {
  COMPANY: {
    NAME: 'Sin City',
    LOGO: logo,
  },
  STAKING_TOKEN: 'SIN',
  REWARD_TOKEN: 'SIN',
};

export const allowedChains: AllowedChainConfig[] = [
  {
    id: 56,
    name: 'BSC Mainnet',
  },
  // {
  //   id: 97,
  //   name: 'BSC Testnet',
  // },
];

export const TOTAL_REWARDS = '2,000,000';

export const ContractAddress = {
  Token: '0x6397de0F9aEDc0F7A8Fa8B438DDE883B9c201010',
  StakingRewards: '0xcD68F150d6DBf0cd1433eafAc639F6aEaDA6309F',
};

export const contracts: ContractConfig[] = [
  {
    name: 'Token',
    abi: TokenABI,
    address: ContractAddress.Token,
  },
  {
    name: 'StakingRewards',
    abi: StakingRewardsABI,
    address: ContractAddress.StakingRewards,
  },
];

export interface ContractInstances {
  Token: IERC20;
  StakingRewards: StakingRewards;
}
