// hooks
import useInput from 'hooks/useInput'
import { useWallet } from 'contexts/Wallet'
import useSwitch from 'hooks/useSwitch'

// libraries
import { toast } from 'react-toastify'
import BN from 'bn.js'

// components
import Spinner from '../Spinner'

// helpers
import Web3 from 'helpers/web3'
import Contracts from 'helpers/contracts'
import { getDate } from 'helpers/utils'

// styles
import classes from 'components/Card/Card.module.scss'

// config
import { APP_CONFIG, ContractAddress, TOTAL_REWARDS } from 'config'
import { validateAmount } from 'helpers/validate'

const StakeCard = (props: any) => {
  const {
    tvl,
    stakingBalance,
    stakedAmount,
    earnedBalance,
    stakingPeriod,
    rewardDate,
    stakingMode,
    hasWithdrawn,
  } = props
  const { account, isConnectedToAllowedNetwork, refresh } = useWallet()
  const stakingAmount = useInput()
  const stakingInProcess = useSwitch()
  const withdrawalInProcess = useSwitch()

  const onStake = async () => {
    if (!account) {
      return toast.info('Please connect your wallet')
    }

    if (!(await isConnectedToAllowedNetwork())) {
      return toast.info('Please connect to one of the supported chains', { theme: 'colored' })
    }

    if (!validateAmount(stakingAmount.value)) {
      return
    }

    try {
      const web3 = Web3.instance
      const _stakingAmount = web3.utils.toWei(stakingAmount.value)
      const { Token, StakingRewards } = Contracts.instances

      stakingInProcess.true()
      const allowance = await Token.methods.allowance(account, ContractAddress.StakingRewards).call()
      if (new BN(_stakingAmount).gt(new BN(allowance))) {
        await Token.methods.approve(ContractAddress.StakingRewards, _stakingAmount).send({ from: account })
      }
      await StakingRewards.methods.stake(_stakingAmount).send({ from: account })
      toast.success('Staked Successfully')
      stakingAmount.reset()
      refresh.rerender()
    } catch (err) {
      console.error(err)
      toast.error('Something went wrong')
    }
    stakingInProcess.false()
  }

  const onWithdraw = async () => {
    if (!account) {
      return toast.info('Please connect to your wallet')
    }

    if (!(await isConnectedToAllowedNetwork())) {
      return toast.info('Please connect to one of the supported chains', { theme: 'colored' })
    }

    if (parseFloat(earnedBalance) === 0) {
      return toast.error('Already withdrawn or did not participate')
    }

    try {
      withdrawalInProcess.true()
      const { StakingRewards } = Contracts.instances
      await StakingRewards.methods.exit().send({ from: account })
      toast.success('Withdrawal successful')
      refresh.rerender()
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong')
    }
    withdrawalInProcess.false()
  }

  const balance = (
    <div>
      <span>Your total</span>{' '}
      <strong>
        {APP_CONFIG.STAKING_TOKEN}: {stakingBalance}
      </strong>
    </div>
  )

  const stats = (
    <>
      <div className={classes.stats}>
        <div className={classes.stat}>
          <span>Currently Staked:</span>
          <strong>
            {stakedAmount} {APP_CONFIG.STAKING_TOKEN}
          </strong>
        </div>
        <div className={classes.stat}>
          Staking Period: <strong>{stakingPeriod} Days</strong>
        </div>
      </div>
      <div className={classes.stats}>
        <div className={classes.stat}>
          Reward Earned:{' '}
          <strong>
            {earnedBalance} {APP_CONFIG.REWARD_TOKEN}
          </strong>
        </div>
        <div className={classes.stat}>
          Reward Date: <strong>{getDate(new Date(rewardDate))}</strong>
        </div>
      </div>
      <div className={classes.stats}>
        <div className={classes.stat}>
          Total Reward: <strong>{TOTAL_REWARDS} {APP_CONFIG.REWARD_TOKEN}</strong>
        </div>
        <div className={classes.stat}>
          Staking Cap: <strong>{tvl.toLocaleString()} {APP_CONFIG.STAKING_TOKEN}</strong>
        </div>
      </div>
    </>
  )

  const staking = (
    <div className={classes.card}>
      <div>
        <div className={classes.heading}>Stake</div>
        {balance}
        <div className={classes.center}>
          <div className={classes.input}>
            <input
              type="number"
              min={0}
              value={stakingAmount.value}
              onChange={stakingAmount.set}
              placeholder="Amount"
              pattern="[0-9]"
              disabled={stakingInProcess.value}
            />
            <button onClick={onStake} disabled={stakingInProcess.value}>
              {stakingInProcess.value ? <Spinner /> : 'Stake'}
            </button>
          </div>
        </div>
        {stats}
        <div className={classes.note}>
          <strong>Note: </strong>
          The staked token will be locked for the whole duration of the program. Only after the reward date{' '}
          {getDate(new Date(rewardDate))}, you can withdraw the total staked amount of token + the staking
          rewards to your wallet in {APP_CONFIG.REWARD_TOKEN}.
        </div>
      </div>
    </div>
  )

  const withdraw = (
    <div className={classes.card}>
      <div>
        <div className={classes.heading}>Withdraw</div>
        {balance}
        <div className={classes.center}>
          <button
            onClick={onWithdraw}
            disabled={withdrawalInProcess.value}
            className={classes.withdrawButton}>
            {withdrawalInProcess.value ? <Spinner /> : 'Withdraw'}
          </button>
        </div>
        {stats}
        <div className={classes.note}>
          <strong>Note: </strong>
          The staked token will be locked for the whole duration of the program. Only after the reward date{' '}
          {getDate(new Date(rewardDate))}, you can withdraw the total staked amount of token + the staking
          rewards to your wallet in {APP_CONFIG.REWARD_TOKEN}.
        </div>
      </div>
    </div>
  )

  const withdrawn = (
    <div className={classes.card}>
      <div>
        <div className={classes.heading}>Withdraw</div>
        {balance}
        <div className={classes.message}>You've already withdrawn your staked amount and rewards.</div>
      </div>
    </div>
  )

  const didntParticipate = (
    <div className={classes.card}>
      <div>
        <div className={classes.heading}>Withdraw</div>
        {balance}
        <div className={classes.message}>You have not participated in the staking program.</div>
      </div>
    </div>
  )

  return stakingMode
    ? staking
    : hasWithdrawn
      ? withdrawn
      : !account
        ? withdraw
        : parseFloat(earnedBalance) === 0
          ? didntParticipate
          : withdraw
}

export default StakeCard
